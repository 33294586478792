import React, { useState } from "react"
import styled from "styled-components"
import { colors } from "../../../styles/helpers"
import {
  backgroundStyles,
  mapPointStyles,
  mapLabelStyles,
} from "./styles/styles"
import PointContent from "./PointContent"
import PinParks from "../MapImages/PinParks"
import PinPonds from "../MapImages/PinPonds"
import PinShops from "../MapImages/PinShops"
import PinSchools from "../MapImages/PinSchools"

const PointStyle = styled.div`
  position: absolute;
  top: ${props => (props.mTop ? props.mTop : "auto")};
  right: ${props => (props.mRight ? props.mRight : "auto")};
  bottom: ${props => (props.mBot ? props.mBot : "auto")};
  left: ${props => (props.mLeft ? props.mLeft : "auto")};
  z-index: ${props => (props.isActive || props.isHover ? 1000 : 1)};

  @media (min-width: 768px) {
    top: ${props => (props.tTop ? props.tTop : "auto")};
    right: ${props => (props.tRight ? props.tRight : "auto")};
    bottom: ${props => (props.tBot ? props.tBot : "auto")};
    left: ${props => (props.tLeft ? props.tLeft : "auto")};
  }

  @media (min-width: 1100px) {
    top: ${props => (props.top ? props.top : "auto")};
    right: ${props => (props.right ? props.right : "auto")};
    bottom: ${props => (props.bottom ? props.bottom : "auto")};
    left: ${props => (props.left ? props.left : "auto")};
  }
`

const PointBackground = styled.div`
  ${backgroundStyles}
  opacity: ${props => (props.isActive ? 1 : 0)};
  visibility: ${props => (props.isActive ? "visible" : "hidden")};
`

const MapPoint = styled.div`
  ${mapPointStyles};
  background-color: ${props =>
    props.type === "ponds"
      ? colors.colorTertiary
      : props.type === "parks"
      ? colors.colorSecondary
      : props.type === "schools"
      ? colors.colorPrimary
      : props.type === "shops"
      ? colors.colorShad
      : colors.colorPrimary};
  z-index: ${props => (props.isHover ? 1000 : 1)};
`

const MapLabel = styled.div`
  ${mapLabelStyles};
  display: block;
  display: none;
  top: 0;
  right: ${props => (props.side === "left" ? "2rem" : "auto")};
  left: ${props => (props.side === "right" ? "4rem" : "auto")};
  z-index: ${props => (props.isHover ? 1000 : 1)};

  @media(max-width: 1023px) {
    display: none;
    }

  &::after {
    display: ${props => (props.side === "right" ? "block" : "none")};
    border-right: 1.5rem solid
      ${props =>
        props.type === "ponds"
          ? colors.colorTertiary
          : props.type === "parks"
          ? colors.colorSecondary
          : props.type === "schools"
          ? colors.colorPrimary
          : props.type === "shops"
          ? colors.colorShad
          : colors.colorPrimary};
    transform-origin: center right;
    transform: ${props => (props.isHover ? "scaleX(1)" : "scaleX(0)")};
    ${props => console.log(props.side)};
  }

  &::before {
    display: ${props => (props.side === "left" ? "block" : "none")};
    right: -1.5rem;
    border-left: 1.5rem solid
      ${props =>
        props.type === "ponds"
          ? colors.colorTertiary
          : props.type === "parks"
          ? colors.colorSecondary
          : props.type === "schools"
          ? colors.colorPrimary
          : props.type === "shops"
          ? colors.colorShad
          : colors.colorPrimary};
    border-top: 1.1rem solid transparent;
    border-bottom: 1.1rem solid transparent;
    transform-origin: center left;
    transform: ${props => (props.isHover ? "scaleX(1)" : "scaleX(0)")};
    content: "";
  }

  h2 {
    padding: 0 2rem;
    background-color: ${props =>
      props.type === "ponds"
        ? colors.colorTertiary
        : props.type === "parks"
        ? colors.colorSecondary
        : props.type === "schools"
        ? colors.colorPrimary
        : props.type === "shops"
        ? colors.colorShad
        : colors.colorPrimary};
    ${props =>
      props.side === "left"
        ? "transform-origin: center right"
        : "transform-origin: center left"};
    transform: ${props => (props.isHover ? "scaleX(1)" : "scaleX(0)")};
    text-align: ${props => (props.side === "left" ? "right" : "left")};

  
  }
`

const PinIcon = styled.div``

const Point = ({
  content,
  image,
  title,
  type,
  top,
  right,
  bottom,
  left,
  tTop,
  tBot,
  tLeft,
  tRight,
  mTop,
  mBot,
  mLeft,
  mRight,
  pSide,
}) => {
  const [isHover, setIsHover] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const pinIcon =
    type === "parks" ? (
      <PinParks />
    ) : type === "ponds" ? (
      <PinPonds />
    ) : type === "shops" ? (
      <PinShops />
    ) : type === "schools" ? (
      <PinSchools />
    ) : null

  return (
    <>
      <PointStyle
        isHover={isHover}
        isActive={isActive}
        type={type}
        top={top}
        bottom={bottom}
        left={left}
        right={right}
        tTop={tTop}
        tBot={tBot}
        tLeft={tLeft}
        tRight={tRight}
        mTop={mTop}
        mBot={mBot}
        mLeft={mLeft}
        mRight={mRight}
      >
        <MapPoint
          type={type}
          isHover={isHover}
          onClick={() => setIsActive(!isActive)}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <PinIcon>{pinIcon}</PinIcon>
        </MapPoint>
        <MapLabel
          isHover={isHover}
          side={pSide}
          className="mapLabel"
          type={type}
        >
          <h2>
            {title} <span>(click to read more)</span>
          </h2>
        </MapLabel>
      </PointStyle>
      <PointContent
        isActive={isActive}
        setIsActive={setIsActive}
        type={type}
        title={title}
        content={content}
        image={image}
        className="mapContent"
      />
      <PointBackground isActive={isActive} onClick={() => setIsActive(false)} />
    </>
  )
}

export default Point
