import React, { useState } from "react"
import styled from "styled-components"
import {
  buttonThree,
  B2Black,
  colors,
  H2Green,
  medWrapper,
  fontSizer,
} from "../../styles/helpers"
import MapMain from "./MapImages/MapMain"

import Point from "./MapPoints/Point"

const AmenitiesMapStyled = styled.section`
  position: relative;
  margin-top: 0;

  .wrapperTop {
    ${medWrapper};
    flex-wrap: wrap;
    position: relative;
    padding: 2rem 0;
  }

  .wrapper {
    ${medWrapper};
    flex-wrap: wrap;
    position: relative;
    padding: 2rem 0;
    max-width: 75rem;

    @media (min-width: 768px) {
      max-width: 110rem;
    }

    @media (min-width: 1025px) {
      max-width: 250rem;
      padding-bottom: 0;
    }
  }

  .mainMapWrap {
    background-color: ${colors.colorAccent};
    position: relative;
    width: 100%;
  }
`

const PinsNavigation = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    margin-bottom: 1rem;
  }

  button {
    ${buttonThree};
    margin: 2rem 1rem;

    @media (max-width: 767px) {
      width: 100%;
      margin: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  button.active {
    background: ${colors.colorPrimary};
    border-color: ${colors.colorPrimary};
    color: ${colors.greyBrown};
    cursor: inherit;
  }
`

const MapTypeDescriptions = styled.div`
  width: 100%;
  max-width: 70rem;
  margin: 2rem auto;
  text-align: center;

  h2 {
    ${H2Green};
  }

  p {
    ${B2Black};
    ${fontSizer(1.6, 1.8, 76.8, 150, 1.8)};
  }
`

const AmenitiesMap = ({
  coopersTown,
  discoveryPark,
  nwPlayground,
  whCroxford,
  willPond,
  centPond,
  pathways,
  boardwalk,
  coopSchool,
  verSchool,
  futurePark,
  windsong,
  stmartin,
  timHortons,
  winners,
  cibc,
  scotiaBank,
  micheals,
  walmart,
  homeDepot,
  northcott,
  chinookWinds,
  shoppersDrug,
  saveOnFoods,
  willPondLook,
  centPondLook,
  londonDrugs,
  transitTerm,
  petland,
  montana,
  coop,
  shell,
  parkEntry,
  pathOne,
  pathTwo,
  horseShoe,
  secret,
}) => {
  const coopersTownContent = coopersTown?.acf?._coo_amen_ctp_content
  const coopersTownImage =
    coopersTown?.acf?._coo_amen_ctp_image?.localFile?.childImageSharp?.fluid

  const londonDrugsContent = londonDrugs?.acf?._coo_amen_lodr_content
  const londonDrugsImage =
    londonDrugs?.acf?._coo_amen_lodr_image?.localFile?.childImageSharp?.fluid

  const transitTermContent = transitTerm?.acf?._coo_amen_soutrn_content
  const transitTermImage =
    transitTerm?.acf?._coo_amen_soutrn_image?.localFile?.childImageSharp?.fluid

  const discoveryParkContent = discoveryPark?.acf?._coo_amen_dipa_content
  const discoveryParkImage =
    discoveryPark?.acf?._coo_amen_dipa_image?.localFile?.childImageSharp?.fluid

  const nwPlaygroundContent = nwPlayground?.acf?._coo_amen_nwpa_content
  const nwPlaygroundImage =
    nwPlayground?.acf?._coo_amen_nwpa_image?.localFile?.childImageSharp?.fluid

  const whCroxfordContent = whCroxford?.acf?._coo_amen_whpa_content
  const whCroxfordImage =
    whCroxford?.acf?._coo_amen_whpa_image?.localFile?.childImageSharp?.fluid

  const willPondContent = willPond?.acf?._coo_amen_wilpo_content
  const willPondImage =
    willPond?.acf?._coo_amen_wilpo_image?.localFile?.childImageSharp?.fluid

  const centPondContent = centPond?.acf?._coo_amen_cenpo_content
  const centPondImage =
    centPond?.acf?._coo_amen_cenpo_image?.localFile?.childImageSharp?.fluid

  const pathwaysContent = pathways?.acf?._coo_amen_patpat_content
  const pathwaysImage =
    pathways?.acf?._coo_amen_patpat_image?.localFile?.childImageSharp?.fluid

  const boardwalkContent = boardwalk?.acf?._coo_amen_brwk_content
  const boardwalkImage =
    boardwalk?.acf?._coo_amen_brwk_image?.localFile?.childImageSharp?.fluid

  const coopSchoolContent = coopSchool?.acf?._coo_amen_copsch_content
  const coopSchoolImage =
    coopSchool?.acf?._coo_amen_copsch_image?.localFile?.childImageSharp?.fluid

  const verSchoolContent = verSchool?.acf?._coo_amen_versch_content
  const verSchoolImage =
    verSchool?.acf?._coo_amen_versch_image?.localFile?.childImageSharp?.fluid

  const futureParkContent = futurePark?.acf?._coo_amen_futpar_content
  const futureParkImage =
    futurePark?.acf?._coo_amen_futpar_image?.localFile?.childImageSharp?.fluid

  const windsongContent = windsong?.acf?._coo_amen_winsch_content
  const windsongImage =
    windsong?.acf?._coo_amen_winsch_image?.localFile?.childImageSharp?.fluid

  const stmartinContent = stmartin?.acf?._coo_amen_stmsch_content
  const stmartinImage =
    stmartin?.acf?._coo_amen_stmsch_image?.localFile?.childImageSharp?.fluid

  const timHortonsContent = timHortons?.acf?._coo_amen_timsho_content
  const timHortonsImage =
    timHortons?.acf?._coo_amen_timsho_image?.localFile?.childImageSharp?.fluid

  const winnersContent = winners?.acf?._coo_amen_winsho_content
  const winnersImage =
    winners?.acf?._coo_amen_winsho_image?.localFile?.childImageSharp?.fluid

  const cibcContent = cibc?.acf?._coo_amen_cibcsho_content
  const cibcImage =
    cibc?.acf?._coo_amen_cibcsho_image?.localFile?.childImageSharp?.fluid

  const scotiaBankContent = scotiaBank?.acf?._coo_amen_scosho_content
  const scotiaBankImage =
    scotiaBank?.acf?._coo_amen_scosho_image?.localFile?.childImageSharp?.fluid

  const michealsContent = micheals?.acf?._coo_amen_macsho_content
  const michealsImage =
    micheals?.acf?._coo_amen_macsho_image?.localFile?.childImageSharp?.fluid

  const walmartContent = walmart?.acf?._coo_amen_walsho_content
  const walmartImage =
    walmart?.acf?._coo_amen_walsho_image?.localFile?.childImageSharp?.fluid

  const homeDepotContent = homeDepot?.acf?._coo_amen_hodesho_content
  const homeDepotImage =
    homeDepot?.acf?._coo_amen_hodesho_image?.localFile?.childImageSharp?.fluid

  const petlandContent = petland?.acf?._coo_amen_petsho_content
  const petlandImage =
    petland?.acf?._coo_amen_petsho_image?.localFile?.childImageSharp?.fluid

  const montanaContent = montana?.acf?._coo_amen_mobqsho_content
  const montanaImage =
    montana?.acf?._coo_amen_mobqsho_image?.localFile?.childImageSharp?.fluid

  const coopContent = coop?.acf?._coo_amen_coopsho_content
  const coopImage =
    coop?.acf?._coo_amen_coopsho_image?.localFile?.childImageSharp?.fluid

  const shellContent = shell?.acf?._coo_amen_shgasho_content
  const shellImage =
    shell?.acf?._coo_amen_shgasho_image?.localFile?.childImageSharp?.fluid

  const northcottContent = northcott?.acf?._coo_amen_norsch_content
  const northcottImage =
    northcott?.acf?._coo_amen_norsch_image?.localFile?.childImageSharp?.fluid

  const chinookWindsContent = chinookWinds?.acf?._coo_amen_chwpar_content
  const chinookWindsImage =
    chinookWinds?.acf?._coo_amen_chwpar_image?.localFile?.childImageSharp?.fluid

  const shoppersDrugContent = shoppersDrug?.acf?._coo_amen_sdmr_content
  const shoppersDrugImage =
    shoppersDrug?.acf?._coo_amen_sdmr_image?.localFile?.childImageSharp?.fluid

  const saveOnFoodsContent = saveOnFoods?.acf?._coo_amen_sof_content
  const saveOnFoodsImage =
    saveOnFoods?.acf?._coo_amen_sof_image?.localFile?.childImageSharp?.fluid

  const willPondLookContent = willPondLook?.acf?._coo_amen_willok_content
  const willPondLookImage =
    willPondLook?.acf?._coo_amen_willok_image?.localFile?.childImageSharp?.fluid

  const centPondLookContent = centPondLook?.acf?._coo_amen_cetlok_content
  const centPondLookImage =
    centPondLook?.acf?._coo_amen_cetlok_image?.localFile?.childImageSharp?.fluid

  const parkEntryContent = parkEntry?.acf?._coo_amen_bripar_content
  const parkEntryImage =
    parkEntry?.acf?._coo_amen_bripar_image?.localFile?.childImageSharp?.fluid

  const pathOneContent = pathOne?.acf?._coo_amen_pwopar_content
  const pathOneImage =
    pathOne?.acf?._coo_amen_pwopar_image?.localFile?.childImageSharp?.fluid

  const pathTwoContent = pathTwo?.acf?._coo_amen_pwtpar_content
  const pathTwoImage =
    pathTwo?.acf?._coo_amen_pwtpar_image?.localFile?.childImageSharp?.fluid

  const horseShoeContent = horseShoe?.acf?._coo_amen_hhpar_content
  const horseShoeImage =
    horseShoe?.acf?._coo_amen_hhpar_image?.localFile?.childImageSharp?.fluid

  const secretContent = secret?.acf?._coo_amen_sftpar_content
  const secretImage =
    secret?.acf?._coo_amen_sftpar_image?.localFile?.childImageSharp?.fluid

  const [typeActive, setTypeActive] = useState("all")

  const displayShops = typeActive === "all" || typeActive === "shops"
  const displaySchools = typeActive === "all" || typeActive === "schools"
  const displayPonds = typeActive === "all" || typeActive === "ponds"
  const displayParks = typeActive === "all" || typeActive === "parks"

  return (
    <AmenitiesMapStyled>
      <div className="wrapperTop">
        {/* <MapTypeDescriptions>
          {typeActive === "all" && (
            <>
              <h2>Life in Cooper's</h2>
              <p>
                Cooper’s Crossing ranks high on walkability and has excellent
                access to schools, groceries, restaurants and more!
              </p>
            </>
          )}

          {typeActive === "shops" && (
            <>
              <h2>Shopping & Dining</h2>
              <p>
                Cooper’s Town Promenade is located right in the community and
                easy to get to via the park system. Groceries, shops, dining and
                office space are all within walking distance.
              </p>
              <p>
                Sierra Springs Supercentre. Walmart, Home Depot, Comfort Inn and
                much more is all directly bordering Cooper’s Crossing. You could
                walk… but it’s OK to drive when your loading up on supplies.
              </p>
            </>
          )}

          {typeActive === "schools" && (
            <>
              <h2>Schools</h2>
              <p>
                Walk to school with six schools, including two elementary
                schools, within walking distance in Cooper’s Crossing. Schools
                include both separate and public from kindergarten to Grade 12.
              </p>
            </>
          )}

          {typeActive === "ponds" && (
            <>
              <h2>Ponds and Wetlands</h2>
              <p>
                Two scenic ponds and a picturesque boardwalk meandering through
                lush wetlands set Cooper’s Crossing apart.
              </p>
            </>
          )}

          {typeActive === "parks" && (
            <>
              <h2>Parks and Pathways</h2>
              <p>
                A fully networked pathway system connects every corner of
                Cooper’s Crossing, linking schools, shopping and parks. It’s
                easy to stay active when walking or biking is the most
                convenient and enjoyable way to get around.
              </p>
            </>
          )}
        </MapTypeDescriptions> */}
        <PinsNavigation typeActive={typeActive}>
          <button
            className={typeActive === "all" ? "active" : ""}
            data-pintype="all"
            type="button"
            onClick={e => {
              setTypeActive(e.target.dataset.pintype)
            }}
          >
            All
          </button>
          <button
            className={typeActive === "shops" ? "active" : ""}
            data-pintype="shops"
            type="button"
            onClick={e => {
              setTypeActive(e.target.dataset.pintype)
            }}
          >
            Shops
          </button>
          <button
            className={typeActive === "schools" ? "active" : ""}
            data-pintype="schools"
            type="button"
            onClick={e => {
              setTypeActive(e.target.dataset.pintype)
            }}
          >
            Schools
          </button>
          <button
            className={typeActive === "ponds" ? "active" : ""}
            data-pintype="ponds"
            type="button"
            onClick={e => {
              setTypeActive(e.target.dataset.pintype)
            }}
          >
            Ponds & Wetlands
          </button>
          <button
            className={typeActive === "parks" ? "active" : ""}
            data-pintype="parks"
            type="button"
            onClick={e => {
              setTypeActive(e.target.dataset.pintype)
            }}
          >
            Parks & Pathways
          </button>
        </PinsNavigation>
      </div>
      <div className="wrapper">
        <div className="mainMapWrap">
          <MapMain />
          {/* Shop Points  */}
          {displayShops && (
            <>
              <Point
                content={coopersTownContent}
                image={coopersTownImage}
                title="Cooper's Town Promenade"
                type="shops"
                bottom="25vw"
                left="37vw"
                tBot="24vw"
                tLeft="37vw"
                mBot="25vw"
                mLeft="37vw"
                pSide="right"
              />
              <Point
                content={shoppersDrugContent}
                image={shoppersDrugImage}
                title="Shoppers Drug Mart"
                type="shops"
                bottom="17.5vw"
                left="30.5vw"
                tBot="18vw"
                tLeft="31vw"
                mBot="18vw"
                mLeft="31vw"
                pSide="left"
              />
              <Point
                content={saveOnFoodsContent}
                image={saveOnFoodsImage}
                title="Save-On Foods"
                type="shops"
                bottom="19.5vw"
                left="39.5vw"
                tBot="20vw"
                tLeft="38vw"
                mBot="20vw"
                mLeft="38vw"
                pSide="right"
              />
              <Point
                content={timHortonsContent}
                image={timHortonsImage}
                title="Tim Hortons"
                type="shops"
                top="7.5vw"
                right="21.5vw"
                tTop="7.5vw"
                tRight="22vw"
                mTop="7.75vw"
                mRight="22vw"
                pSide="left"
              />
              <Point
                content={winnersContent}
                image={winnersImage}
                title="Winners"
                type="shops"
                top="20vw"
                right="21.5vw"
                tTop="20vw"
                tRight="22vw"
                mTop="18vw"
                mRight="22.5vw"
                pSide="left"
              />
              <Point
                content={cibcContent}
                image={cibcImage}
                title="CIBC"
                type="shops"
                top="32vw"
                right="13vw"
                tTop="30vw"
                tRight="14vw"
                mTop="32vw"
                mRight="14vw"
                pSide="left"
              />
              <Point
                content={scotiaBankContent}
                image={scotiaBankImage}
                title="Scotia Bank"
                type="shops"
                top="40vw"
                right="8.5vw"
                tTop="39vw"
                tRight="10vw"
                mTop="40vw"
                mRight="10vw"
                pSide="left"
              />

              <Point
                content={michealsContent}
                image={michealsImage}
                title="Micheals Arts Crafts"
                type="shops"
                top="34vw"
                right="18vw"
                tTop="34vw"
                tRight="18vw"
                mTop="33.5vw"
                mRight="18.5vw"
                pSide="left"
              />

              <Point
                content={londonDrugsContent}
                image={londonDrugsImage}
                title="London Drugs"
                type="shops"
                top="40vw"
                right="16vw"
                tTop="40vw"
                tRight="16vw"
                mTop="39vw"
                mRight="17vw"
                pSide="left"
              />

              <Point
                content={walmartContent}
                image={walmartImage}
                title="Walmart"
                type="shops"
                top="50vw"
                right="13.5vw"
                tTop="48vw"
                tRight="13.5vw"
                mTop="48vw"
                mRight="14vw"
                pSide="left"
              />
              <Point
                content={homeDepotContent}
                image={homeDepotImage}
                title="Home Depot"
                type="shops"
                top="60vw"
                right="12.5vw"
                tTop="60vw"
                tRight="13.5vw"
                mTop="58vw"
                mRight="13.5vw"
                pSide="left"
              />

              <Point
                content={petlandContent}
                image={petlandImage}
                title="Petland"
                type="shops"
                top="27vw"
                right="19.5vw"
                tTop="24vw"
                tRight="20vw"
                mTop="25vw"
                mRight="20vw"
                pSide="left"
              />

              <Point
                content={montanaContent}
                image={montanaImage}
                title="Montana’s BBQ"
                type="shops"
                top="15.5vw"
                right="19.5vw"
                tTop="16vw"
                tRight="19.5vw"
                mTop="15.5vw"
                mRight="18vw"
                pSide="left"
              />

              <Point
                content={coopContent}
                image={coopImage}
                title="Co-op Grocery"
                type="shops"
                top="10vw"
                right="9vw"
                tTop="10vw"
                tRight="9vw"
                mTop="10vw"
                mRight="9vw"
                pSide="left"
              />

              <Point
                content={shellContent}
                image={shellImage}
                title="Shell Gas"
                type="shops"
                bottom="25vw"
                left="30vw"
                tBot="25vw"
                tLeft="30vw"
                mBot="25vw"
                mLeft="30vw"
                pSide="left"
              />

              <Point
                content={transitTermContent}
                image={transitTermImage}
                title="South Transit Terminal"
                type="shops"
                bottom="23vw"
                right="12.5vw"
                tBot="23vw"
                tRight="13.5vw"
                mBot="-100vw"
                mRight="-100vw"
                pSide="left"
              />
            </>
          )}
          {/* School Points  */}
          {displaySchools && (
            <>
              <Point
                content={northcottContent}
                image={northcottImage}
                title="Northcott Prairie School"
                type="schools"
                bottom="3vw"
                right="37vw"
                tBot="4vw"
                tRight="38vw"
                mBot="4vw"
                mRight="38vw"
                pSide="left"
              />
              <Point
                content={whCroxfordContent}
                image={whCroxfordImage}
                title="WH Croxford High School"
                type="schools"
                top="10vw"
                left="20vw"
                tTop="10vw"
                tLeft="19vw"
                mTop="10vw"
                mLeft="19vw"
                pSide="right"
              />
              <Point
                content={windsongContent}
                image={windsongImage}
                title="Windsong Heights School"
                type="schools"
                bottom="38vw"
                left="14vw"
                tBot="39vw"
                tLeft="14vw"
                mBot="39vw"
                mLeft="14vw"
                pSide="right"
              />
              <Point
                content={coopSchoolContent}
                image={coopSchoolImage}
                title="Coopers Crossing School"
                type="schools"
                top="37.5vw"
                left="47.5vw"
                tTop="37.5vw"
                tLeft="47.5vw"
                mTop="37.5vw"
                mLeft="47.5vw"
                pSide="right"
              />
              <Point
                content={verSchoolContent}
                image={verSchoolImage}
                title="St. Veronica"
                type="schools"
                bottom="30vw"
                right="32vw"
                tBot="31vw"
                tRight="32vw"
                mBot="31vw"
                mRight="32vw"
                pSide="right"
              />

              <Point
                content={stmartinContent}
                image={stmartinImage}
                title="St. Martin de Porres High School"
                type="schools"
                top="1vw"
                right="40vw"
                tTop="1vw"
                tRight="41vw"
                mTop="1vw"
                mRight="41vw"
                pSide="right"
              />
            </>
          )}

          {/* Pond & wetland Points  */}
          {displayPonds && (
            <>
              <Point
                content={willPondContent}
                image={willPondImage}
                title="Willow Pond"
                type="ponds"
                top="12.5vw"
                right="32.5vw"
                tTop="12vw"
                tRight="33vw"
                mTop="12vw"
                mRight="33vw"
                pSide="right"
              />
              <Point
                content={willPondLookContent}
                image={willPondLookImage}
                title="Willow Pond Lookout"
                type="ponds"
                top="14.5vw"
                right="40vw"
                tTop="12vw"
                tRight="33vw"
                mTop="12vw"
                mRight="33vw"
                pSide="right"
              />
              <Point
                content={centPondContent}
                image={centPondImage}
                title="Central Pond"
                type="ponds"
                bottom="46vw"
                right="45vw"
                tBot="47.5vw"
                tRight="45vw"
                mBot="47.5vw"
                mRight="45vw"
                pSide="left"
              />
              <Point
                content={centPondLookContent}
                image={centPondLookImage}
                title="Central Pond Lookout"
                type="ponds"
                top="37vw"
                right="44vw"
                tTop="36vw"
                tRight="45vw"
                mTop="35vw"
                mRight="45vw"
                pSide="right"
              />
              <Point
                content={boardwalkContent}
                image={boardwalkImage}
                title="Boardwalk"
                type="ponds"
                top="39vw"
                right="45vw"
                tTop="39vw"
                tRight="46vw"
                mTop="40vw"
                mRight="47vw"
                pSide="right"
              />
            </>
          )}
          {/* Park & Pathways Points  */}
          {displayParks && (
            <>
              <Point
                content={parkEntryContent}
                image={parkEntryImage}
                title="Park Entry"
                type="parks"
                top="18vw"
                right="46vw"
                tTop="18vw"
                tRight="46vw"
                mTop="18vw"
                mRight="46vw"
                pSide="right"
              />

              <Point
                content={pathOneContent}
                image={pathOneImage}
                title="Pathway System"
                type="parks"
                top="29vw"
                left="44vw"
                tTop="29vw"
                tLeft="44vw"
                mTop="29vw"
                mLeft="44vw"
                pSide="right"
              />

              <Point
                content={pathTwoContent}
                image={pathTwoImage}
                title="Pathway System"
                type="parks"
                top="32vw"
                right="38.5vw"
                tTop="32vw"
                tRight="38.5vw"
                mTop="32vw"
                mRight="38.5vw"
                pSide="right"
              />
              <Point
                content={discoveryParkContent}
                image={discoveryParkImage}
                title="Discovery Park"
                type="parks"
                left="45vw"
                bottom="39vw"
                tLeft="45vw"
                tBot="39vw"
                mLeft="45vw"
                mBot="39vw"
                pSide="right"
              />

              <Point
                content={secretContent}
                image={secretImage}
                title="Secret Forest Trail"
                type="parks"
                left="46.5vw"
                bottom="37vw"
                tLeft="46.5vw"
                tBot="37vw"
                mLeft="46.5vw"
                mBot="37vw"
                pSide="right"
              />

              <Point
                content={horseShoeContent}
                image={horseShoeImage}
                title="Horseshoe Hill"
                type="parks"
                left="44vw"
                bottom="36vw"
                tLeft="44vw"
                tBot="36vw"
                mLeft="44vw"
                mBot="36vw"
                pSide="left"
              />

              <Point
                content={nwPlaygroundContent}
                image={nwPlaygroundImage}
                title="NW Playground"
                type="parks"
                top="17vw"
                left="37.5vw"
                tTop="16vw"
                tLeft="36.5vw"
                mTop="16vw"
                mLeft="36.5vw"
                pSide="right"
              />
              <Point
                content={pathwaysContent}
                image={pathwaysImage}
                title="Pathways"
                type="parks"
                top="14vw"
                left="43vw"
                tTop="13vw"
                tLeft="40vw"
                mTop="13vw"
                mLeft="40vw"
                pSide="right"
              />

              <Point
                content={futureParkContent}
                image={futureParkImage}
                title="Reynolds Park"
                type="parks"
                bottom="24vw"
                right="43.5vw"
                tBot="25vw"
                tRight="45vw"
                mBot="25vw"
                mRight="45vw"
                pSide="right"
              />
              <Point
                content={chinookWindsContent}
                image={chinookWindsImage}
                title="Chinook Winds Park"
                type="parks"
                top="30vw"
                left="1vw"
                tTop="30vw"
                tLeft="1vw"
                mTop="32vw"
                mLeft="1vw"
                pSide="right"
              />
            </>
          )}
        </div>
      </div>
    </AmenitiesMapStyled>
  )
}

export default AmenitiesMap
