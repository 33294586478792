import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const PinWrap = styled.div`
  width: 100%;
  height: 100%;
`

const getData = graphql`
  {
    pin: file(relativePath: { eq: "map-icons-shopping.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const PinShops = () => {
  const data = useStaticQuery(getData)
  const imageData = data.pin.childImageSharp.fluid
  return (
    <PinWrap>
      <Img fluid={imageData} alt="Coopers Crossing Category - Shopping" />
    </PinWrap>
  )
}

export default PinShops
