import { B1White, H2White, colors, fontSizer } from "../../../../styles/helpers"
import { keyframes, css } from "styled-components"

const breatheAnimation = keyframes`
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255,255,255, 1);
	}

	80% {
		transform: scale(1);
		box-shadow: 0 0 0 2rem rgba(255,255,255,0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255,255,255,0);
	}
`

export const backgroundStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(120, 119, 124, 0.5);
  transition: all 0.2s ease-out;
  z-index: 10;
`

export const mapPointStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
  animation-name: ${breatheAnimation};
  animation-duration: 4s;
  animation-iteration-count: infinite;

  @media (min-width: 768px) {
    width: 2.5rem;
    height: 2.5rem;
    border: solid 0.25rem ${colors.white};
  }
`

export const mapLabelStyles = css`
  position: absolute;
  width: 35rem;
  transition: all 0.25s ease-out;
  text-align: left;

  &::after {
    position: absolute;
    top: 0;
    left: -1.5rem;
    width: 0;
    height: 0;
    border-top: 1.1rem solid transparent;
    border-bottom: 1.1rem solid transparent;
    transition: all 0.25s ease-out;
    transform-origin: center right;
    content: "";
  }

  &::before {
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    transition: all 0.25s ease-out;
    content: "";
  }

  h2 {
    ${B1White};
    ${fontSizer(1.2, 1.4, 76.8, 150, 1.4)};
    width: 100%;
    margin: 0;
    transition: all 0.3s ease-out;

    span {
      ${fontSizer(1, 1, 76.8, 150, 1)};
    }
  }
`

export const mapContentStyles = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 37.5rem;
  transition: all 0.5s ease-in-out;
  z-index: 100;

  @media (min-width: 768px) {
    width: 70rem;
  }

  .mapContent__image {
    width: calc(100%);
    min-height: 20rem;

    @media (min-width: 768px) {
      width: calc(25%);
      min-height: auto;
    }

    &--bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .mapContent__content {
    width: calc(100%);
    padding: 1.5rem;

    @media (min-width: 768px) {
      width: calc(75%);
      padding: 3rem;
    }

    h2 {
      ${H2White};
      margin-top: 0;
    }

    p {
      ${B1White};

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  button {
    display: inline-block;
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: ${colors.white};
    width: 4rem;
    height: 4rem;
    transition: all 0.3s ease-out;
    border: none;
    border-radius: 50%;
    font-size: 3.6rem;
    cursor: pointer;

    @media (max-width: 767px) {
      line-height: 1;
    }

    @media (min-width: 768px) {
      width: 2.5rem;
      height: 2.5rem;
      font-size: 1.6rem;
    }

    &:hover {
      background-color: ${colors.colorPrimary};
      color: ${colors.white};
    }
  }
`
