import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Intro from "../components/Amenities/Intro"
import AmenitiesMap from "../components/Amenities/AmenitiesMap"
import WhyAirdrie from "../components/Amenities/WhyAirdrie"
import CallOut from "../components/Amenities/Callout"
import OurBuilders from "../components/Amenities/OurBuilders"

const Amenities = props => {
  const {
    seoInfo,
    topQuote,
    discoveryPark,
    coopersTown,
    nwPlayground,
    whCroxford,
    willPond,
    centPond,
    pathways,
    boardwalk,
    coopSchool,
    verSchool,
    futurePark,
    windsong,
    stmartin,
    timHortons,
    winners,
    cibc,
    scotiaBank,
    micheals,
    walmart,
    homeDepot,
    northcott,
    chinookWinds,
    whyAirdrie,
    callOut,
    buildLogos,
    shoppersDrug,
    saveOnFoods,
    willPondLook,
    centPondLook,
    londonDrugs,
    transitTerm,
    petland,
    montana,
    coop,
    shell,
    parkEntry,
    pathOne,
    pathTwo,
    horseShoe,
    secret
  } = props.data
  const location = props.location.pathname
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <Intro data={topQuote} />
      <AmenitiesMap
        coopersTown={coopersTown}
        discoveryPark={discoveryPark}
        nwPlayground={nwPlayground}
        whCroxford={whCroxford}
        willPond={willPond}
        centPond={centPond}
        pathways={pathways}
        boardwalk={boardwalk}
        coopSchool={coopSchool}
        verSchool={verSchool}
        futurePark={futurePark}
        windsong={windsong}
        stmartin={stmartin}
        timHortons={timHortons}
        winners={winners}
        cibc={cibc}
        scotiaBank={scotiaBank}
        micheals={micheals}
        walmart={walmart}
        homeDepot={homeDepot}
        northcott={northcott}
        chinookWinds={chinookWinds}
        shoppersDrug={shoppersDrug}
        saveOnFoods={saveOnFoods}
        willPondLook={willPondLook}
        centPondLook={centPondLook}
        londonDrugs={londonDrugs}
        transitTerm={transitTerm}
        petland={petland}
        montana={montana}
        coop={coop}
        shell={shell}
        parkEntry={parkEntry}
        pathOne={pathOne}
        pathTwo={pathTwo}
        horseShoe={horseShoe}
        secret={secret}
      />
      <WhyAirdrie whyAirdrie={whyAirdrie} />
      <CallOut data={callOut} />
      <OurBuilders buildLogos={buildLogos} />
    </Layout>
  )
}

export const amenitiesQuery = graphql`
  query amenitiesPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    topQuote: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_toqu_title
        _coo_toqu_content
      }
    }

    coopersTown: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_ctp_content
        _coo_amen_ctp_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    timHortons: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_timsho_content
        _coo_amen_timsho_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    winners: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_winsho_content
        _coo_amen_winsho_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    londonDrugs: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_lodr_content
        _coo_amen_lodr_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    transitTerm: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_soutrn_content
        _coo_amen_soutrn_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    cibc: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_cibcsho_content
        _coo_amen_cibcsho_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    scotiaBank: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_scosho_content
        _coo_amen_scosho_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    micheals: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_macsho_content
        _coo_amen_macsho_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    walmart: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_walsho_content
        _coo_amen_walsho_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    homeDepot: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_hodesho_content
        _coo_amen_hodesho_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }


    petland: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_petsho_content
        _coo_amen_petsho_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    montana: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_mobqsho_content
        _coo_amen_mobqsho_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    coop: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_coopsho_content
        _coo_amen_coopsho_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    shell: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_shgasho_content
        _coo_amen_shgasho_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    discoveryPark: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_dipa_content
        _coo_amen_dipa_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    shoppersDrug: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_sdmr_content
        _coo_amen_sdmr_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    saveOnFoods: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_sof_content
        _coo_amen_sof_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    nwPlayground: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_nwpa_content
        _coo_amen_nwpa_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    futurePark: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_futpar_content
        _coo_amen_futpar_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    whCroxford: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_whpa_content
        _coo_amen_whpa_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    windsong: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_winsch_content
        _coo_amen_winsch_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    northcott: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_norsch_content
        _coo_amen_norsch_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    stmartin: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_stmsch_content
        _coo_amen_stmsch_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    willPond: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_wilpo_content
        _coo_amen_wilpo_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    willPondLook: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_willok_content
        _coo_amen_willok_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    centPond: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_cenpo_content
        _coo_amen_cenpo_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    centPondLook: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_cetlok_content
        _coo_amen_cetlok_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    pathways: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_patpat_content
        _coo_amen_patpat_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }


    parkEntry: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_bripar_content
        _coo_amen_bripar_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    pathOne: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_pwopar_content
        _coo_amen_pwopar_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    pathTwo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_pwtpar_content
        _coo_amen_pwtpar_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }


    horseShoe: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_hhpar_content
        _coo_amen_hhpar_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    secret: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_sftpar_content
        _coo_amen_sftpar_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    boardwalk: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_brwk_content
        _coo_amen_brwk_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    coopSchool: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_copsch_content
        _coo_amen_copsch_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    verSchool: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_versch_content
        _coo_amen_versch_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    chinookWinds: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_amen_chwpar_content
        _coo_amen_chwpar_image {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    whyAirdrie: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coop_why_air_title
        _coop_why_air_content
        _coop_why_air_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    callOut: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_hoca_top_title
        _coo_hoca_top_content
        _coo_hoca_top_btn_text
        _coo_hoca_top_btn_link_two
        _coo_hoca_top_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_hoca_bg_color
      }
    }

    buildLogos: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_oubu_title
        _coo_oubu_content
        _coo_oubu_builder_logo {
          logo {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          url
        }
      }
    }
  }
`

export default Amenities
