import React from "react"
import BgImage from "gatsby-background-image"
import styled from "styled-components"
import { H1White, H2White } from "../../styles/helpers"

const WhyAirdrieStyled = styled.section`
  position: relative;
  min-height: 45rem;
  margin-top: 5rem;

  @media (min-width: 768px) {
    min-height: 75rem;
    margin-top: 0;
  }

  .mainContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 50rem;
    padding: 1rem 2rem;
    text-align: center;
    z-index: 10;

    @media (min-width: 768px) {
      max-width: 110rem;
    }

    h2 {
      ${H1White};
    }

    p {
      ${H2White};
      font-weight: normal;
    }
  }

  .backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &__wrapper {
      width: 100%;
      height: 100%;
    }
  }

  .backgroundOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 1;
  }
`

const WhyAirdrie = ({ whyAirdrie }) => {
  return (
    <WhyAirdrieStyled>
      <div className="mainContent">
        <div>
          <div>
            <h2>{whyAirdrie?.acf?._coop_why_air_title}</h2>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: whyAirdrie?.acf?._coop_why_air_content,
            }}
          />
        </div>
      </div>
      <div className="backgroundImage">
        <BgImage
          className="backgroundImage__wrapper"
          tag="div"
          fluid={
            whyAirdrie?.acf?._coop_why_air_image?.localFile?.childImageSharp
              ?.fluid
          }
        />
      </div>
      <div className="backgroundOverlay" />
    </WhyAirdrieStyled>
  )
}

export default WhyAirdrie
