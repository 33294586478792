import React from "react"
import styled from "styled-components"
import BGImage from "gatsby-background-image"
import { colors } from "../../../styles/helpers"
import { mapContentStyles } from "./styles/styles"

const MapContent = styled.div`
  ${mapContentStyles};
  background-color: ${props =>
    props.type === "ponds"
      ? colors.colorTertiary
      : props.type === "parks"
      ? colors.colorSecondary
      : props.type === "schools"
      ? colors.colorPrimary
      : props.type === "shops"
      ? colors.colorShad
      : colors.colorPrimary};
  transform: ${props =>
    props.isActive ? "translate(-50%, -50%)" : "translate(-50%, 0%)"};
  opacity: ${props => (props.isActive ? 1 : 0)};
  visibility: ${props => (props.isActive ? "visible" : "hidden")};
  z-index: ${props => (props.isActive ? 10000 : 1)};
`

const PointContent = ({
  isActive,
  setIsActive,
  title,
  type,
  image,
  content,
}) => {
  return (
    <MapContent isActive={isActive} type={type} className="mapContent">
      <div className="mapContent__image">
        <BGImage
          className="mapContent__image--bg"
          tag="div"
          fluid={image}
          alt="Cooper's Town Promenade"
        />
      </div>
      <div className="mapContent__content">
        <div>
          <h2>{title}</h2>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
      <button onClick={() => setIsActive(!isActive)}>&times;</button>
    </MapContent>
  )
}

export default PointContent
