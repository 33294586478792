import React from "react"
import styled from "styled-components"
import { standardWrapper, H1Green, H3Black } from "../../styles/helpers"

const TopQuoteSection = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h1 {
      ${H1Green};
    }
  }

  .quote {
    width: 100%;
    text-align: center;

    p {
      ${H3Black};
      font-weight: 300;
    }
  }
`

const Intro = ({ data }) => {
  const title = data.acf._coo_toqu_title
  const content = data.acf._coo_toqu_content
  return (
    <TopQuoteSection>
      <div className="wrapper">
        <div className="title">
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <div className="quote">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </TopQuoteSection>
  )
}

export default Intro
