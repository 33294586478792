import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import {
  buttonThree,
  colors,
  H1Green,
  H3Black,
  medWrapper,
} from "../../styles/helpers"

const OurBuildersStyled = styled.section`
  background: rgba(229, 226, 204, 0.25);
  margin-bottom: 5rem;

  .wrapper {
    ${medWrapper};
  }

  .buildersContent {
    width: 100%;
    text-align: center;

    h2 {
      ${H1Green};
    }

    p {
      ${H3Black};
    }
  }

  .buildersLogos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(50% - 2rem);
      margin: 1rem;
      background-color: ${colors.white};
      box-shadow: 0.7rem 0.8rem 1rem 0 rgba(0, 0, 0, 0.16);

      @media (min-width: 768px) {
        width: calc(33.33% - 2rem);
        margin: 1rem;
      }

      @media (min-width: 1025px) {
        width: calc((100% / 6) - 2rem);
        margin: 2rem 1rem;
      }

      .gatsby-image-wrapper {
        width: 100%;
        max-width: 100%;

        img {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }

  .builderLink {
    width: 100%;
    margin: 4rem auto;
    text-align: center;

    a {
      ${buttonThree};
    }
  }
`

const OurBuilders = ({ buildLogos }) => {
  return (
    <OurBuildersStyled>
      <div className="wrapper">
        <div className="buildersContent">
          <div>
            <h2>{buildLogos?.acf?._coo_oubu_title}</h2>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: buildLogos?.acf?._coo_oubu_content,
            }}
          />
        </div>
        <div className="buildersLogos">
          {buildLogos?.acf?._coo_oubu_builder_logo.map((logo, index) => {
            return (
              <div className="buildersLogos__logo" key={index}>
                <Img
                  fluid={logo?.logo?.localFile?.childImageSharp?.fluid}
                  alt={logo?.logo?.alt_text}
                />
              </div>
            )
          })}
        </div>

        <div className="builderLink">
          <Link to="/show-homes/builders-info">Builders Details</Link>
        </div>
      </div>
    </OurBuildersStyled>
  )
}

export default OurBuilders
